import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Relatorio } from '../../shared/util/relatorio.model';
import { Resultado } from '../../shared/util/resultado.model';
import { ParcelaReceber } from './parcela-receber.model';

@Injectable()
export class ParcelaReceberService extends VsCrudService<ParcelaReceber> {

    public url: string = "api/parcelasReceber";
    pageResult: PageResult = new PageResult();

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
    }

    public list(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/search', search).subscribe(
            res => {
                self.pageResult = res;
            }
        );
    }

    public listByAreaCliente(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchByAreaCliente', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public listUltimasSeisDuplicatasAbertas(favorecidoId: number) {
        return this.get(`${this.url}/listUltimasSeisDuplicatas/${favorecidoId}`)
            .toPromise()
            .then(res => {
                return res;
            });
    }

    public listParcelas(search: SearchParams) {
        let self = this;
        return this.search(this.url + '/searchParcelas', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

    public gerarBoleto(idDuplicataReceber: number, idParcelaReceber: number) {
        return this.get(`${this.url}/gerarBoleto/${idDuplicataReceber}&${idParcelaReceber}`)
            .toPromise()
            .then(res => <Relatorio>res);
    }


    public estornar(id: number) {

        return this.get(`${this.url}/estornar/${id}`)
            .toPromise()
            .then(res => <Resultado>res);
    }

    public cancelar(id: number) {

        return this.get(`${this.url}/cancelar/${id}`)
            .toPromise()
            .then(res => <Resultado>res);
    }


    public gerarExtratoDuplicataReceber(idLoja: Number, idDuplicataReceber: number, idParcelaReceberExtrato: number) {
        return this.get(`${this.url}/imprimirExtratoDuplicataReceber/${idLoja}/${idDuplicataReceber}/${idParcelaReceberExtrato}`)
            .toPromise()
            .then(res => <Relatorio>res);
    }

    public gerarExtratoParcela(idParcela: number) {
        return this.get(`${this.url}/extratoParcela/${idParcela}`)
            .toPromise().then(res => <Relatorio>res);
    }

    public imprimirBoleto(idDuplicataReceber: number, idParcelaReceber: number) {
        return this.get(`${this.url}/imprimirBoleto/${idDuplicataReceber}&${idParcelaReceber}`)
            .toPromise()
            .then(res => <Relatorio>res);
    }

    public imprimirBoletoPedidoOnline(idDuplicataReceber: number, idParcelaReceber: number) {
        return this.get(`${this.url}/imprimirBoletoPedidoOnline/${idDuplicataReceber}&${idParcelaReceber}`)
            .toPromise()
            .then(res => <Relatorio>res);
    }
}


